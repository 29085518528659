import { template as template_036cf08870cb4691acb62b6e170fa9db } from "@ember/template-compiler";
const SidebarSectionMessage = template_036cf08870cb4691acb62b6e170fa9db(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
