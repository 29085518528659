import { template as template_41e6a894772d452a92319411c284ec77 } from "@ember/template-compiler";
const FKControlMenuContainer = template_41e6a894772d452a92319411c284ec77(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
