import { template as template_b9dfb23ac6894f4d9c8251cf3cd10863 } from "@ember/template-compiler";
const FKLabel = template_b9dfb23ac6894f4d9c8251cf3cd10863(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
